<template>
  <el-card shadow="never" v-loading="loading">
    <div slot="header">
      <span class="iconfont icon-AppStore">&nbsp;应用商店</span>
    </div>
    <div class="card_header_flex">
      <div>
        <el-button type="primary" size="small" @click="dialogAddAppStore=true">新增应用</el-button>
      </div>
      <div>
        <el-input
          v-model="search"
          placeholder="中文名、英文名、包名"
          suffix-icon="el-icon-search"
          style="width:300px"
          size="small"
          @change="initLoad"
        ></el-input>
      </div>
    </div>
    <el-table :data="list.slice((currentPage-1)*pageSize,currentPage*pageSize)" size="mini">
      <el-table-column prop="apkId" label="序号" sortable></el-table-column>
      <el-table-column prop="imgUrl" label="APK图标">
        <template v-slot:default="scope">
          <img :src="fileUrl+scope.row.imgUrl" style="height:100px;width:100px;" />
        </template>
      </el-table-column>
      <el-table-column prop="cnName" label="中文名称"></el-table-column>
      <el-table-column prop="enName" label="英文名称"></el-table-column>
      <el-table-column prop="apkIntroduction" label="APK简介" show-overflow-tooltip></el-table-column>
      <el-table-column prop="packetName" label="APK包名"></el-table-column>
      <el-table-column prop="version" label="版本号"></el-table-column>
      <el-table-column prop="apkSize" label="APK大小" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.apkSize}}MB</span>
        </template>
      </el-table-column>
      <el-table-column prop="version" label="版本号"></el-table-column>
      <el-table-column prop="publicity" label="是否公开" sortable>
        <template slot-scope="scope">
          <span>{{scope.row.publicity=='on'?'公开':'不公开'}}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="openEdit(scope.row)">编辑</el-button>
          <el-button type="text" @click="delAppStore(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page_right">
      <el-pagination
        background
        layout="total,prev, pager, next ,jumper"
        :total="list.length"
        :page-size="pageSize"
        :current-page="currentPage"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>

    <!-- 新增应用 -->
    <el-dialog
      :visible.sync="dialogAddAppStore"
      :close-on-click-modal="false"
      @closed="closeDialogAddAppStore"
      width="45%"
    >
      <span slot="title">新增应用</span>
      <div v-show="step==1" class="uploadDialog">
        <el-upload
          ref="apk-upload"
          drag
          :action="actionUrlApk"
          accept=".apk"
          :on-success="handleSuccess"
          :before-upload="handleBefore"
          :headers="header"
          :show-file-list="true"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">
            将文件拖到此处，或
            <em>点击上传</em>
          </div>
          <div class="el-upload__tip" slot="tip">只能上传apk文件，且不超过50M</div>
        </el-upload>
      </div>
      <div v-show="step==2">
        <el-form
          :model="form"
          ref="form"
          label-width="80px"
          size="small"
          style="padding:20px"
          hide-required-asterisk
        >
          <el-form-item label="图标">
            <el-dropdown placement="bottom">
              <div>
                <div class="avatar-uploader-logo">
                  <el-image
                    :src="fileUrl+form.imgUrl"
                    style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);"
                    fit="contain"
                  ></el-image>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="dialogAddFileUpload=true"
                  style="padding:0"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                <el-dropdown-item style="padding:0">
                  <el-upload
                    style="text-align:center"
                    :action="actionUrl"
                    accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                    :headers="header"
                    :data="{type:0,category:'图标'}"
                    :show-file-list="false"
                    :on-success="handleImgAddSuccess"
                    :before-upload="beforeImgAddUpload"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>

          <el-form-item label="应用分类">
            <el-select v-model="form.apkType" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="中文名称"
            prop="cnName"
            :rules="{ required: true, message: '请输入中文名称', trigger: 'blur' }"
          >
            <el-input v-model="form.cnName" placeholder="请输入中文名称"></el-input>
          </el-form-item>
          <el-form-item
            label="英文名称"
            prop="enName"
            :rules="{ required: true, message: '请输入英文名称', trigger: 'blur' }"
          >
            <el-input v-model="form.enName" placeholder="请输入英文名称"></el-input>
          </el-form-item>
          <el-form-item
            label="应用介绍"
            prop="apkIntroduction"
            :rules="{ required: true, message: '请输入应用介绍', trigger: 'blur' }"
          >
            <el-input
              v-model="form.apkIntroduction"
              type="textarea"
              :rows="6"
              placeholder="请输入应用介绍"
            ></el-input>
          </el-form-item>
          <el-form-item label="APK地址">{{form.apkUrl}}</el-form-item>
          <el-form-item label="是否公开">
            <el-radio-group v-model="form.publicity" size="mini">
              <el-radio-button label="on">公开</el-radio-button>
              <el-radio-button label="off">不公开</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="dialogAddAppStore=false">取消</el-button>
        <el-button type="primary" size="small" @click="onSubmitAddApp">确定</el-button>
      </span>
    </el-dialog>

    <!-- 添加窗口--添加图片 -->
    <el-dialog
      :visible.sync="dialogAddFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogAddFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogAddFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitAddImgUrl" size="small">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 修改应用 -->
    <el-dialog
      :visible.sync="dialogEditAppStore"
      :close-on-click-modal="false"
      width="45%"
      @closed="closeDialogEditAppStore"
    >
      <span slot="title">编辑应用</span>
      <div>
        <el-form
          :model="editForm"
          ref="editForm"
          label-width="80px"
          size="small"
          style="padding:20px"
          hide-required-asterisk
        >
          <el-form-item label="图标">
            <el-dropdown placement="bottom">
              <div>
                <div class="avatar-uploader-logo">
                  <el-image
                    :src="fileUrl+editForm.imgUrl"
                    style="width: 100px; height: 100px;background:rgba(235,235,235,0.5);"
                    fit="contain"
                  ></el-image>
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  @click.native="dialogEditFileUpload=true"
                  style="padding:0"
                >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;资源库选择&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-dropdown-item>
                <el-dropdown-item style="padding:0">
                  <el-upload
                    style="text-align:center"
                    :action="actionUrl"
                    accept=".jpg, .jpeg, .png, .JPG, .JPEG"
                    :headers="header"
                    :data="{type:0,category:'图标'}"
                    :show-file-list="false"
                    :on-success="handleImgEditSuccess"
                    :before-upload="beforeImgEditUpload"
                  >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;本地上传&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</el-upload>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </el-form-item>

          <el-form-item label="应用分类">
            <el-select v-model="editForm.apkType" placeholder="请选择" style="width:100%">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            label="中文名称"
            prop="cnName"
            :rules="{ required: true, message: '请输入中文名称', trigger: 'blur' }"
          >
            <el-input v-model="editForm.cnName" placeholder="请输入中文名称"></el-input>
          </el-form-item>
          <el-form-item
            label="英文名称"
            prop="enName"
            :rules="{ required: true, message: '请输入英文名称', trigger: 'blur' }"
          >
            <el-input v-model="editForm.enName" placeholder="请输入英文名称"></el-input>
          </el-form-item>
          <el-form-item
            label="应用介绍"
            prop="apkIntroduction"
            :rules="{ required: true, message: '请输入应用介绍', trigger: 'blur' }"
          >
            <el-input
              v-model="editForm.apkIntroduction"
              type="textarea"
              :rows="6"
              placeholder="请输入应用介绍"
            ></el-input>
          </el-form-item>
          <el-form-item label="APK地址">
            <el-input v-model="editForm.apkUrl" disabled>
              <template slot="append">
                <el-upload
                  ref="apk-editUpload"
                  :action="actionUrlApk"
                  accept=".apk"
                  :on-success="handleEditSuccess"
                  :before-upload="handleEditBefore"
                  :headers="header"
                  :show-file-list="false"
                >
                  <el-button style="color:#5e72f9">上传APK</el-button>
                </el-upload>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item label="是否公开">
            <el-radio-group v-model="editForm.publicity" size="mini">
              <el-radio-button label="on">公开</el-radio-button>
              <el-radio-button label="off">不公开</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-form>
      </div>

      <span slot="footer">
        <el-button size="small" @click="dialogEditAppStore=false">取消</el-button>
        <el-button type="primary" size="small" @click="onSubmitEditApp">确定</el-button>
      </span>
    </el-dialog>

    <!-- 修改窗口--添加图片 -->
    <el-dialog
      :visible.sync="dialogEditFileUpload"
      :close-on-click-modal="false"
      width="55%"
      @closed="closeDialogFileUpload"
    >
      <span slot="title">资源库</span>
      <FileUpload :choice="true" :type="0" :category="'图标'" v-if="dialogEditFileUpload"></FileUpload>
      <div slot="footer" class="dialog-footer-button">
        <el-button plain type="primary" @click="dialogEditFileUpload = false" size="small">取 消</el-button>
        <el-button type="primary" @click="onSubmitEditImgUrl" size="small">确 定</el-button>
      </div>
    </el-dialog>
  </el-card>
</template>
<script>
// import { init } from 'echarts';
import {
  getAppStoreApi,
  addAppStoreApi,
  delAppStoreApi,
  delAppStoreApis
} from "../../api/data";
import FileUpload from "../../components/FileUpload";
import Aes from "../../utils/aes";
export default {
  components: {
    FileUpload
  },
  data() {
    return {
      loading: true,
      search: "", //查询条件
      currentPage: 1, //当前页
      pageSize: 10, //一页显示多少条
      list: [],
      dialogAddAppStore: false, //添加窗口
      dialogAddFileUpload: false, //添加窗口中图片资源库
      dialogEditAppStore: false, //修改窗口
      dialogEditFileUpload: false, //修改窗口中图片资源库
      form: {
        apkId: "",
        apkUrl: "",
        imgUrl: "",
        cnName: "",
        enName: "",
        packetName: "",
        apkIntroduction: "",
        apkType: "",
        publicity: ""
      },
      editForm: {},
      options: [
        {
          value: "直播",
          label: "直播"
        },
        {
          value: "影视",
          label: "影视"
        },
        {
          value: "视频",
          label: "视频"
        },
        {
          value: "音乐",
          label: "音乐"
        },
        {
          value: "游戏",
          label: "游戏"
        }
      ],
      step: 1, //添加应用当前步骤
      deleteConten: "" //删除时返回的内容
    };
  },
  computed: {
    header() {
      //上传头部POST请求头部带token信息验证登录状态
      return {
        accessToken: this.$store.state.user.authorization
      };
    },
    //图片显示地址
    fileUrl() {
      return this.$store.state.fileUrl;
    },
    //上传路径
    actionUrl() {
      return this.$store.state.fileUrl + "/file/upload";
    },
    //文件上传
    actionUrlApk() {
      return this.$store.state.fileUrl + "/user_apk/upload";
    }
  },
  created() {
    this.initLoad();
  },
  methods: {
    async initLoad() {
      await getAppStoreApi({ param: this.search }).then(res => {
        console.log(res);
        this.list = res.data;
        this.loading = false;
      });
    },
    //新增应用
    onSubmitAddApp() {
      this.$refs["form"].validate(valid => {
        if (valid) {
          addAppStoreApi(this.form).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogAddAppStore = false;
              this.initLoad();
            }
          });
        } else {
          return false;
        }
      });
    },
    //删除应用
    async delAppStore(obj) {
      await delAppStoreApis({ id: obj.apkId }).then(res => {
        console.log(res);
        this.deleteConten = res.data;
      });
      const h = this.$createElement;
      this.$msgbox({
        title: "消息",
        message: h("p", null, [
          h("div", null, "此操作将永久删除该应用, 是否继续?"),
          // h('span', null, ''),
          h(
            "i",
            { style: "color:red" },
            "此apk有：" + this.deleteConten + "个位置使用"
          )
        ]),
        showCancelButton: true,
        confirmButtonText: "确定",
        cancelButtonText: "取消"
      }).then(() => {
        delAppStoreApi({ apkId: obj.apkId }).then(res => {
          if (res) this.$message.success(res.msg);
          this.initLoad();
        });
      });
    },
    //选择第几页
    handleCurrentChange(currentPage) {
      this.currentPage = currentPage;
    },
    //关闭添加窗口回调
    closeDialogAddAppStore() {
      this.step = 1;
      this.$refs["form"].resetFields();
    },
    //图片窗口关闭事件
    closeDialogFileUpload() {
      this.$store.commit("setFileList", []);
    },
    //添加窗口 更改图标
    onSubmitAddImgUrl() {
      this.dialogAddFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.form.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //添加应用本地上传
    //上传完成事件
    handleImgAddSuccess(res) {
      console.log(res);
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.form.imgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
      this.initLoad();
    },
    //上传前事件
    beforeImgAddUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },

    //添加上传APK
    //上传前验证判断
    handleBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleSuccess(response) {
      console.log(response);
      const res = JSON.parse(Aes.decode(response));
      if (res.type == "success") {
        if (res.msg == "equal") {
          //查询到有相同的APK包提示是否覆盖
          this.$confirm("存在相同APP,是否覆盖?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            //确认覆盖就将返回的带APPid对象存入form中
            this.form = res.data;
            this.step = 2;
            this.form.apkType = "直播";
          });
        } else {
          this.step = 2;
          this.$message.success(res.msg);
          delete res.data.apkId;
          res.data.publicity = "on";
          res.data.apkType = "直播";
          this.form = res.data;
        }
      } else {
        this.$message.error(res.msg);
      }
      this.$refs["apk-upload"].clearFiles();
    },
    //打开修改窗口
    openEdit(obj) {
      this.editForm = { ...obj };
      this.dialogEditAppStore = true;
    },
    //修改窗口 更改图标
    onSubmitEditImgUrl() {
      this.dialogEditFileUpload = false;
      if (this.$store.state.fileList.length > 0) {
        this.editForm.imgUrl = this.$store.state.fileList[0].filePath;
        this.$store.commit("setFileList", []);
      }
    },
    //修改应用本地上传
    //上传完成事件
    handleImgEditSuccess(res) {
      var result = JSON.parse(Aes.decode(res));
      if (result.type == "success") {
        this.editForm.imgUrl = result.msg;
      } else {
        this.$message.error(result.msg);
      }
      this.initLoad();
    },
    //上传前事件
    beforeImgEditUpload(file) {
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.warning("上传图片大小不能超过 5MB!");
      }
      return isLt5M;
    },
    //保存编辑
    onSubmitEditApp() {
      this.$refs["editForm"].validate(valid => {
        if (valid) {
          addAppStoreApi(this.editForm).then(res => {
            if (res) {
              this.$message.success(res.msg);
              this.dialogEditAppStore = false;
              this.initLoad();
            }
          });
        } else {
          return false;
        }
      });
    },
    //关闭修改窗口事件
    closeDialogEditAppStore() {
      this.$refs["editForm"].resetFields();
    },

    //修改上传APK
    //上传前验证判断
    handleEditBefore(file) {
      const isLt50M = file.size / 1024 / 1024 < 50;
      if (!isLt50M) {
        this.$message.warning("上传APK大小不能超过 50MB!");
      }
      return isLt50M;
    },
    //上传成功
    handleEditSuccess(response) {
      const res = JSON.parse(Aes.decode(response));
      if (res.type == "success") {
        if (res.msg == "equal") {
          //查询到有相同的APK包提示是否覆盖
          this.$confirm("存在相同APP无法上传,请重新选择!", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning"
          }).then(() => {
            //确认覆盖就将返回的带APPid对象存入form中
            // this.editForm = res.data;
          });
        } else {
          this.$message.success(res.msg);
          delete res.data.apkId;
          this.editForm.packetName = res.data.packetName;
          this.editForm.apkUrl = res.data.apkUrl;
        }
      } else {
        this.$message.error(res.msg);
      }
      this.$refs["apk-editUpload"].clearFiles();
    }
  }
};
</script>
<style lang='scss'>
.uploadDialog {
  text-align: center;
  padding: 50px;
}
.avatar-uploader-logo {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  position: relative;
  overflow: hidden;
  width: 100px;
  height: 100px;
}
.avatar-uploader-logo:hover {
  border-color: #5e72f9;
}
.avatar-uploader-logo-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
</style>
